import { Autocomplete, TextField } from '@mui/material';
import { FormikProps } from 'formik';
import i18next from 'i18next';
import React from 'react';
import * as Yup from 'yup';

import { IFormInput } from 'src/components/Form/types';
import SelectInput from 'src/components/Inputs/SelectInput';
import TextInput from 'src/components/Inputs/TextInput';
import { DEFAULT_COMPANY } from 'src/helpers/constants';
import { Roles } from 'src/helpers/enums/roles';
import { ICompany } from 'src/types/company';
import { INewUser, IUser } from 'src/types/user';

import '../UsersNew/i18n';

export const validationSchema = Yup.object({
  firstName: Yup.string().required('First name is required').trim(),
  lastName: Yup.string().required('Last name is required').trim(),
  email: Yup.string().email('Email is invalid').required('Email is required').trim(),
  company: Yup.string().required().trim(),
});

export const getInitialValuesUserForm = (userToEdit?: IUser | null) => {
  return {
    firstName: userToEdit ? userToEdit.firstName : '',
    lastName: userToEdit ? userToEdit.lastName : '',
    email: userToEdit ? userToEdit.email : '',
    company: userToEdit ? userToEdit.company.id : '',
    role: userToEdit ? userToEdit.role : Roles.USER,
  };
};

export const getInputsFormUser = (config: FormikProps<INewUser>, companies: ICompany[] | null) => {
  const inputs: IFormInput[] = [
    {
      key: 'firstName',
      input: (
        <TextInput
          id="firstName"
          name="firstName"
          placeholder="First name"
          error={
            config.touched.firstName && Boolean(config.errors.firstName)
              ? (config.errors.firstName as string)
              : ''
          }
          value={config.values.firstName}
          isDisabled={false}
          onChange={config.handleChange}
        />
      ),
    },
    {
      key: 'lastName',
      input: (
        <TextInput
          id="lastName"
          name="lastName"
          placeholder="Last name"
          error={
            config.touched.lastName && Boolean(config.errors.lastName)
              ? (config.errors.lastName as string)
              : ''
          }
          value={config.values.lastName}
          isDisabled={false}
          onChange={config.handleChange}
        />
      ),
    },
    {
      key: 'email',
      input: (
        <TextInput
          id="email"
          name="email"
          placeholder="Email"
          error={
            config.touched.email && Boolean(config.errors.email)
              ? (config.errors.email as string)
              : ''
          }
          value={config.values.email}
          isDisabled={false}
          onChange={config.handleChange}
        />
      ),
    },
    {
      key: 'company',
      input: (
        <Autocomplete
          id="company"
          fullWidth
          autoComplete
          options={companies || []}
          getOptionLabel={(option: ICompany) => option.name}
          noOptionsText={i18next.t('USERS_NEW:NO_RESULTS')}
          value={
            companies?.find((company) => company.id === config.values.company) || DEFAULT_COMPANY
          }
          renderOption={(props, option: ICompany) => {
            return (
              <li data-testid="option" {...props}>
                {option.name}
              </li>
            );
          }}
          renderInput={(params) => {
            return (
              <TextField
                {...params}
                name="company"
                label="Companies"
                error={config.touched.company && Boolean(config.errors.company)}
              />
            );
          }}
          onChange={(event, companySelected) => {
            if (companySelected) {
              config.setFieldValue('company', companySelected.id);
            }
          }}
        />
      ),
    },
    {
      key: 'role',
      input: (
        <SelectInput
          id="role"
          name="role"
          label="User Role"
          error={
            config.touched.role && Boolean(config.errors.role) ? (config.errors.role as string) : ''
          }
          value={config.values.role}
          onChange={config.handleChange}
          options={[
            {
              text: 'Admin',
              value: Roles.ADMIN,
            },
            {
              text: 'User',
              value: Roles.USER,
            },
            {
              text: 'Sales',
              value: Roles.SALES,
            },
            // {
            //   text: 'News',
            //   value: Roles.NEWS,
            // },
          ]}
        />
      ),
    },
  ];
  return inputs;
};

export const mapToUserToShow = (user: any) => ({
  name: user.name,
  company: user.company?.name,
  email: user.email,
  registerUrl: user.registerUrl,
  changedPassword: user.changedPassword ? 'Yes' : 'No',
});
